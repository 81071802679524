import React from "react"
import loadable from "@loadable/component"
import "../../css/typography-roboto.css"
import "../../css/bvk-custom.css"
const ChatComponent = loadable(() => import("../../components/Chat"))

function Chat() {
  // doing this loadable import so createStore works
  // https://stackoverflow.com/questions/65398447/webpackerror-typeerror-object-is-not-a-function-gatsbyjs-build-error-bot
  return (
    <ChatComponent
      name="Übungsassistent"
      // token="3jwgou3DF10.A35SD0t0jPu-rCUgveaj4VUNYjOyV_Afesxi5A9J02c"
      token="-BeR0fBw97I.tXA01fMOkW4qALJZ9KvPzRUM4Y7Smfi0xrYi2xus8-s" // bots-prod-01
      // token="AhaaisD-ce0.7csNC_FrUbij1tjiy_bqmh-hON-1H7nc0SA-L5RG4jE" // bot-process-dev-01
      botId="1c6yj36gkqkyypau"
      avatar="false"
      domain="https://europe.directline.botframework.com/v3/directline"
      webSocket={false}
    />
  )
}

export default Chat
